@tailwind base;
@tailwind components;
@tailwind utilities;

:root,
.light,
.light-theme {
  --red-1: #fefdfc;
  --red-2: #fff7f5;
  --red-3: #FDF2ED;
  --red-4: #fddfd3;
  --red-5: #fcd2c2;
  --red-6: #f5c5b2;
  --red-7: #ebb39d;
  --red-8: #df9a7e;
  --red-9: #be6f4f;
  --red-10: #b06343;
  --red-11: #9d573a;
  --red-12: #463129;

  --red-a1: #aa550003;
  --red-a2: #ff33000a;
  --red-a3: #eb3e0019;
  --red-a4: #f446002c;
  --red-a5: #f343003d;
  --red-a6: #de3f004d;
  --red-a7: #cb3a0062;
  --red-a8: #c0380081;
  --red-a9: #a12f00b0;
  --red-a10: #942c00bc;
  --red-a11: #802600c5;
  --red-a12: #230a00d6;

  --red-contrast: #fff;
  --red-surface: #fff5f3cc;
  --red-indicator: #be6f4f;
  --red-track: #be6f4f;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root,
    .light,
    .light-theme {
      --red-1: oklch(99.4% 0.0018 42.48);
      --red-2: oklch(98.2% 0.0086 42.48);
      --red-3: oklch(95.5% 0.0197 42.48);
      --red-4: oklch(92.5% 0.0379 42.48);
      --red-5: oklch(89.5% 0.0518 42.48);
      --red-6: oklch(86.1% 0.0607 42.48);
      --red-7: oklch(81.3% 0.0722 42.48);
      --red-8: oklch(74.6% 0.0919 42.48);
      --red-9: oklch(62.2% 0.1104 42.48);
      --red-10: oklch(58.1% 0.1104 42.48);
      --red-11: oklch(53.1% 0.102 42.48);
      --red-12: oklch(33.6% 0.0328 42.48);

      --red-a1: color(display-p3 0.6745 0.349 0.0235 / 0.012);
      --red-a2: color(display-p3 0.9059 0.2196 0.0235 / 0.04);
      --red-a3: color(display-p3 0.7961 0.2588 0.0118 / 0.095);
      --red-a4: color(display-p3 0.8353 0.2667 0.0039 / 0.165);
      --red-a5: color(display-p3 0.8314 0.2745 0.0039 / 0.232);
      --red-a6: color(display-p3 0.7608 0.2471 0.0039 / 0.291);
      --red-a7: color(display-p3 0.698 0.2235 0.0039 / 0.373);
      --red-a8: color(display-p3 0.6627 0.2118 0.0039 / 0.487);
      --red-a9: color(display-p3 0.5529 0.1765 0 / 0.667);
      --red-a10: color(display-p3 0.5098 0.1608 0 / 0.71);
      --red-a11: color(display-p3 0.4392 0.1373 0 / 0.75);
      --red-a12: color(display-p3 0.1176 0.0392 0 / 0.836);

      --red-contrast: #fff;
      --red-surface: color(display-p3 0.9922 0.9647 0.9529 / 0.8);
      --red-indicator: oklch(62.2% 0.1104 42.48);
      --red-track: oklch(62.2% 0.1104 42.48);
    }
  }
}

body {
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

.bg-stripe-gradient {
  background: repeating-linear-gradient(
    -55deg,
    #1f8269,
    #1f8269 8px,
    #34a387 8px,
    #34a387 13px
  );
}

.non-draggale {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.spin-wheel-animation {
  transform: rotate(var(--start-rotation-degrees));
}

.spin-wheel-animation.started-spinning {
  animation: spin-wheel var(--start-spinning-time)
      cubic-bezier(0.71, var(--spin-wheel-cb-arg), 0.96, 0.9) 0s 1 normal
      forwards running,
    continueSpin-wheel var(--continue-spinning-time) linear
      var(--start-spinning-time) 1 normal forwards running,
    stopSpin-wheel var(--stop-spinning-time) cubic-bezier(0, 0, 0.35, 1.02)
      var(--stop-spin-time-duration) 1 normal forwards running;
}

@keyframes spin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--start-rotation-degree-complete));
  }
}
@keyframes continueSpin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--start-rotation-degree-complete));
  }
}
@keyframes stopSpin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--final-rotation-degrees));
  }
}
